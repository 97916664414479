<template>
  <div>
    {{ espece.libelle }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            especes: 'identification/especes'
        }),
        espece(){
            return [...this.especes].find(item => item.uid === this.object.espece)
        }
    }

}
</script>